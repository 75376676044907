// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";
// import Splide from '@splidejs/splide';
// import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
console.log(`Hello ${process.env.HELLO}`);

// const splide = new Splide(".splide", {
//   type: 'loop',
//   drag: 'false',
//   focus: 'center',
//   perPage: 3,
//   autoScroll: {
//     speed: 1,
//   },
//   arrows: false,
//   pagination: false,
//   autoWidth: true,
//   autoHeight: true,
// })
// splide.mount({AutoScroll});
//
//
// export class BeeBloomGallery{
//   constructor(backgroundId,imageClass,timeout) {
//     this.backgroundId=backgroundId;
//     this.imageClass=imageClass;
//     this.timeout=timeout||1000;
//     console.log(backgroundId)
//     this.backgroundDiv=document.getElementById(backgroundId);
//     console.log(this.backgroundDiv)
//     this.imageDivs=document.getElementsByClassName(imageClass);
//     this.currentImage=0;
//   }
//
//   changeImage(index){
//     var element=this.imageDivs[index];
//     console.log(element.src);
//     this.backgroundDiv.src = `${element.src}`;
//   }
//
//   changeImagesAutoPlay(){
//     setInterval(()=>{
//       var nextImage=(this.currentImage+1)%this.imageDivs.length;
//       this.changeImage(nextImage);
//       this.currentImage=nextImage;
//     },this.timeout)
//   }
// }
//
//
// const beeBoomGallery=new BeeBloomGallery("beebloom-preview-image","beebloom-gallery-image",3000);
// // beeBoomGallery.changeImagesAutoPlay();
//
//  document.getElementById("beebloom-preview-image").addEventListener("mouseover", beeBoomGallery.changeImagesAutoPlay());
